import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Radiant Auto Spa
			</title>
			<meta name={"description"} content={"Бездоганна чистота, бездоганний догляд - Radiant Auto Spa!"} />
			<meta property={"og:title"} content={"Radiant Auto Spa"} />
			<meta property={"og:description"} content={"Бездоганна чистота, бездоганний догляд - Radiant Auto Spa!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link4" />
			<Override slot="link3" />
		</Components.Header2>
		<Components.Hero>
			<Override slot="text1">
				В автосервісі Radiant Auto Spa ми пропонуємо широкий спектр послуг, розроблених для задоволення всіх ваших потреб у догляді за автомобілем. Від глибокого очищення до спеціалізованої обробки деталей, наші послуги гарантують, що кожен аспект вашого автомобіля отримає увагу, на яку він заслуговує. Наша команда використовує лише найкращі засоби та технології, щоб перетворити ваш автомобіль, покращивши його зовнішній вигляд та захистивши його вартість.
			</Override>
			<Override slot="text">
				Наше портфоліо послуг
			</Override>
		</Components.Hero>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-9">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 70px 0px"
				lg-flex-direction="column"
				text-align="center"
				flex-direction="column"
				max-width="100%"
			>
				<Text margin="0px 0px 20px 0px" color="--darkL1" font="--lead" width="70%">
					Наші послуги - це не просто чистка, це підтримка вашого автомобіля в найкращому стані. Серце Radiant Auto Spa - це досвід нашої команди та передові інструменти, які вони використовують. Кожен член команди навчений працювати з різними моделями та типами автомобілів, забезпечуючи персоналізований догляд, пристосований до конкретних потреб вашого автомобіля.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
				align-content="center"
				align-items="center"
				justify-items="center"
				justify-content="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					background="--color-darkL2"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Комплексні мийки
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Зовнішня мийка: Включає миття кузова, полірування шин та миття вікон.{"\n"}
							<br />
							<br />
							{" "}Прибирання салону: Пилосос, чистка оббивки та полірування салону.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Детальні послуги
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Повна деталізація: Повне очищення зсередини та деталізація, щоб омолодити ваш автомобіль.{"\n"}
							<br />
							<br />
							Express Detail: Швидке, але ретельне очищення для тих, хто завжди в дорозі.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Спеціальні послуги
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Захист лакофарбового покриття: Нанесення воскових або керамічних покриттів для захисту та покращення лакофарбового покриття вашого автомобіля.{"\n"}
							<br />
							<br />
							{" "}Очищення двигуна: Детальне очищення моторного відсіку для видалення бруду та нагару, покращення продуктивності та довговічності.
						</Text>
					</LinkBox>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Нестандартні рішення
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Відновлення фар: Відновлює прозорість каламутних фар, покращуючи видимість вночі.
Усунення запахів: Спеціалізовані процедури для усунення неприємних запахів та освіження салону вашого автомобіля.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
					grid-column="span 2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Сезонні пропозиції
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Підготовка до зими: Спеціальні процедури для захисту вашого автомобіля від солі та морозу.
Літній блиск: Послуги, які допоможуть вашому автомобілю виглядати прохолодним і чистим у спекотну погоду.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline3" md-margin="0px 0px 30px 0px">
					Дізнайтеся більше з нами!
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
					Це лише короткий огляд того, що ми пропонуємо в автосервісі Radiant Auto Spa. Кожна послуга розроблена таким чином, щоб не тільки очистити, але й зберегти естетику та функціональність вашого автомобіля. Щоб отримати детальну інформацію про те, як ми можемо задовольнити потреби вашого автомобіля, завітайте до нас або зв'яжіться з нами!
				</Text>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" font="--headline3" text-transform="uppercase">
				Заплануйте своє обслуговування вже сьогодні!
			</Override>
			<Override slot="text1" text-transform="uppercase" font="--lead">
				Навіщо чекати? Забезпечте своєму автомобілю догляд, на який він заслуговує. Зв'яжіться з нами, щоб записатися на обслуговування або завітайте до автосалону Radiant Auto Spa, щоб ознайомитися з повним спектром наших пропозицій. Ваш автомобіль буде вам вдячний!
			</Override>
		</Components.Cta>
		<Components.Header2>
			<Override slot="text" />
		</Components.Header2>
		<Components.Footer>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});